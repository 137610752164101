<template>
  <div>
    <b-modal v-model="modalShow" size="lg" :title="$t('kanban.title')" footer-class="footerClass"
      @hidden="modalCancel"
      content-class="shadow" no-close-on-backdrop
      scrollable
    >
      <b-form-group :label="$t('kanban.name')" label-for="name">
          <b-form-input id="name" 
            :data-vv-as="$t('kanban.name')"
            data-vv-name="kanban.name"
            data-vv-delay="500"
            v-model="profileData.name"
            v-validate="{ required: true }"/>
          <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('kanban.name') }}
          </b-form-invalid-feedback>
      </b-form-group>

      <div class="kanban-stage-container" v-for="(column, index) in columnData" v-bind:key="index">
        <div class="kanban-stage-name">Stage: <b>{{column.name}}</b></div>

        <div class="row">
          <div class="col-sm">
            <b-form-group :label="$t('kanban.color')" :for="'color'+index">
              <b-form-input type="color" :id="'color'+index" 
                :data-vv-as="$t('kanban.color')"
                data-vv-name="kanban.color"
                data-vv-delay="500"
                v-model="column.color"
                />
            </b-form-group>
          </div>
          <div class="col-sm">
            <b-form-group :label="$t('kanban.limit')" :for="'limit'+index">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button @click.prevent="limitAdd(index, -1)">
                      <font-awesome-icon :icon="['far', 'minus']"/>
                    </b-button>
                  </b-input-group-prepend>

                  <b-form-input :id="'limit'+index" 
                    data-vv-name="kanban.limit"
                    data-vv-delay="500"
                    v-model="column.limit"
                    :placeholder="$t('kanban.placeholder.limit')"
                    type="text" @blur="limitFormat(column)">
                  </b-form-input>

                  <b-input-group-append>
                    <b-button @click.prevent="limitAdd(index, 1)">
                      <font-awesome-icon :icon="['far', 'plus']"/>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
            </b-form-group>
          </div>
        </div>
  
        <div class="row">
          <div class="col-sm">
            <b-form-group :label="$t('kanban.progress')" :for="'progress'+index">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button @click.prevent="progressAdd(column, -1)">
                      <font-awesome-icon :icon="['far', 'minus']"/>
                    </b-button>
                  </b-input-group-prepend>

                  <b-form-input :id="'progress'+index" 
                    data-vv-name="kanban.progress"
                    data-vv-delay="500"
                    v-model="column.progress"
                    :placeholder="$t('kanban.placeholder.progress')"
                    type="text" @blur="progressFormat(column)">
                  </b-form-input>

                  <b-input-group-append>
                    <b-button @click.prevent="progressAdd(column, 1)">
                      <font-awesome-icon :icon="['far', 'plus']"/>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
            </b-form-group>
          </div>
          <div class="col-sm">
            <b-form-group :label="isStageVisible(column)" :for="'visible'+index">
              <b-form-checkbox switch :id="'visible'+index"
                data-vv-name="kanban.visible"
                data-vv-delay="500"
                v-model="column.visible"
                />
            </b-form-group>
          </div>
        </div>
      </div>

      <template v-slot:modal-footer="{  }">
        <b-button size="sm" variant="secondary" style="margin-right: auto;" @click="resetDefaults">{{ $t('button.defaults') }}</b-button>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template>
          <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
          <b-button size="sm" variant="danger" @click="cancel">{{ $t('button.cancel') }}</b-button>
        </template>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { cloneDeep } from "lodash";
import kSettings from "@/_kanbanSettings";

export default {
  name: 'KanbanSettingsModal',
  props: {
    show:      { type: Boolean, required: true },
    columns:   {
      type: Array, required: true, default: function() {return []}
    },
    profile:   {
      validator: prop => typeof prop === 'object' || prop === null
    },
    projects:   {
      type: Object, required: true, default: function() {return {}}
    }, 
  },
  data() {
    return {
      modalShow: false,
      user: null,
      profileData: {},
      columnData: {}
    }
  },
  watch: {
    show(newValue) {
      // When modal first opens
      if (!this.modalShow && newValue) {
        this.originalProfile = cloneDeep(this.profile);
        this.originalColumns = cloneDeep(this.columns);
        this.profileData = cloneDeep(this.profile);
        this.columnData = cloneDeep(this.columns);
        for (const column of this.columnData) {
          delete column.tasks;
        }
      }
      if(newValue != this.modalShow) {
        this.$validator.resume();
        this.modalShow = newValue;
      }
    },
  },
  computed: {
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'settings.name');
    },
  },
  methods: {
    isStageVisible(column) {
      return this.$t(column.visible?'kanban.stage_visible_on':'kanban.stage_visible_off');
    },
    progressAdd(column, delta) {
      if(isNaN(column.progress)) {
        column.progress = 0;
      }
      // If we start on 0 and we decrement, set to null to represent no value
      // as this field is optional.
      if ((column.progress == null || column.progress <= 0) && delta < 0) {
        column.progress = null;
        return;
      }

      const value = parseInt(column.progress);
      column.progress = value + delta;

      // round to nearest 10
      if (column.progress % 10 !== 0) {
        if (delta > 0) {
          column.progress = Math.ceil(column.progress / 10) * 10;
        }
        else {
          column.progress = Math.floor(column.progress / 10) * 10;
        }
      }
      this.progressFormat(column);
    },
    progressFormat(column) {
      // Null or empty values are allowed -- this field is optional
      if (column.progress === null || column.progress === "") {
        column.progress = null;
        return;
      }

      if(isNaN(column.progress)) {
        column.progress = 0;
      }
      const value = parseInt(column.progress);
      if(value < 0) {
        column.progress = 0;
      } else if(value > 100) {
        column.progress = 100;
      } else {
        column.progress = value;
      }
    },
    limitAdd(index, delta) {
      const column = this.columnData[index];
      if(isNaN(column.limit)) {
        column.limit = 1;
      }
      // If we start on 1 and we decrement, set to null to represent no value
      // as this field is optional.
      if ((column.limit == null || column.limit <= 1) && delta < 0) {
        column.limit = null;
        return;
      }
      const value = parseInt(column.limit);
      this.$set(this.columnData[index], 'limit', value + delta);
      this.limitFormat(column);
    },
    limitFormat(column) {
      // Null or empty values are allowed -- this field is optional
      if (column.limit === null || column.limit === "") {
        column.limit = null;
        return;
      }

      if(isNaN(column.limit)) {
        column.limit = 1;
      }
      const value = parseInt(column.limit);
      if(value < 1) {
        column.limit = 1;
      } else if(value > 9999) {
        column.limit = 9999;
      } else {
        column.limit = value;
      }
    },
    ok() {
      this.$emit('update:show', false);
      this.$emit('success', { profile: this.profileData, columns: this.columnData });
    },
    cancel() {
      this.$emit('update:show', false);
    },
    resetDefaults() {

      // The fields above cause the list to rebuild which would override
      // the changes we make here, so force them to happen after
      let self = this;
    
      let stageSet = {};
      for (var uuId in self.projects) {
        let project = self.projects[uuId];
        project.stageList.forEach(stage => {
          stageSet[stage.uuId] = stage;
        });
      }

      for (var i = 0; i < self.columnData.length; i++) {
        var column = self.columnData[i];
        let stage = stageSet[column.uuId];
        column.name = stage.name;
        column.color = kSettings.getDefaultColor(i);
        column.visible = true;
        delete column.limit;
        delete column.progress;
      }
    },
    modalCancel() {
      this.$validator.pause();
      this.$emit('update:show', false);
    }
  }
}
</script>

<style lang="scss">
.indent {
  margin-left: 1.5em;
}

.indent-two {
  margin-left: 3em;
}

.setting-checkbox {
  font-size: 1em !important;
  margin-top: 4px;
  margin-bottom: 4px;
  line-height: 2.3 !important;
}

.kanban-stage-container {
  border: 1px solid var(--form-control-border);
  border-radius: 4px;
  margin: 10px 0;
  padding: 5px 5px 0 5px;
}
.kanban-stage-name {
  text-align: center;
  margin-bottom: 4px;
}
</style>